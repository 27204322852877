import React from "react"
import { css } from "@emotion/core"
import Layout from "../../components/Layout"
import ProjectHero from "../../components/heros/ProjectHero"
import DoubleColumn from "../../components/projects/DoubleColumn"
import BannerDoubleColumn from "../../components/projects/BannerDoubleColumn"
import Download from "../../components/projects/Download"
import Logo from "../../components/projects/Logo"
import NextProject from "../../components/projects/NextProject"

import HeroImage from "../../images/projects/hallid.ai/hero.jpg"
import ProjectMobileImage from "../../images/projects/hallid.ai/project_mobile.png"
import BannerImage from "../../images/projects/hallid.ai/banner.gif"
import ColumnImage from "../../images/projects/hallid.ai/column.jpg"
import ColumnImage2 from "../../images/projects/hallid.ai/column2.jpg"
import InstallImage from "../../images/projects/hallid.ai/install.jpg"
import LogoImage from "../../images/projects/hallid.ai/logo.png"

import NextImage from "../../images/projects/info/aycm.jpg"

const Footer = () => (
  <Download
    css={css`
      margin-bottom: 100px;
      justify-content: flex-start;

      @media (max-width: 650px) {
        justify-content: center;
        margin-bottom: 0;
      }
    `}
    androidLink="https://play.google.com/store/apps/details?id=io.deverest.isaacandroid"
    iosLink="https://apps.apple.com/us/app/meet-fridai/id1465605705"
  />
)

const Next = () => (
  <NextProject
    image={NextImage}
    title="AYCM – All You Can Move"
    description="The AYCM SportPass is a digital sports pass for almost all sports facilities in Hungary."
    to="aycm"
  />
)

const Hero = () => (
  <ProjectHero title="Fridai" subtitle="The virtual gaming assistant" />
)

const HallidaiProject = () => (
  <Layout withHero heroContent={Hero} heroImage={HeroImage} infoContent={Next}>
    <div
      css={theme => css`
        text-align: center;
        min-height: calc(
          100vh - ${theme.constants.infoFullHeight / 2}px -
            ${theme.constants.footerHeight}px -
            ${theme.constants.headerHeight}px
        );
        justify-content: center;
        display: flex;
        flex-direction: column;

        @media (max-width: 1000px) {
          position: relative;
          top: -100px;
        }

        @media (max-width: 650px) {
          position: relative;
          top: -130px;
        }
      `}
    >
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
        `}
      >
        <DoubleColumn
          first
          title="Connect your games you play!"
          description="A voice assistant delivering the unique experience of undisrupted gaming sessions and personalized gaming."
          image={ProjectMobileImage}
          flip
          css={css`
            @media (max-width: 1000px) {
              padding-top: 0;
            }
          `}
          imageOverride={css`
            @media (max-width: 650px) {
              height: 93vw;
            }

            @media (min-width: 1920px) {
              height: 700px;
              padding: 0;
            }
          `}
        />
      </div>
      <BannerDoubleColumn
        title=""
        description="Fridai is voice activated, it is like having a friend sitting next to you while gaming to help you with anything you need so you can focus on immersing yourself in your favorite games."
        image={BannerImage}
        top={100}
        border={0}
        color="#0C0C26"
        column1={5}
        css={css`
          background-size: 100%;
          padding: 50px 0;
        `}
        imageOverride={css`
          @media (max-width: 650px) {
            height: 94vw;
          }

          @media (min-width: 1920px) {
            height: 500px;
            padding: 0;
          }
        `}
      />
      <div
        css={theme => css`
          margin: ${theme.constants.pageMargin};

          @media (max-width: 1200px) {
            margin: ${theme.constants.mediumpageMargin};
          }

          @media (max-width: 1000px) {
            margin: ${theme.constants.smallpageMargin};
          }
          margin-top: 100px;
        `}
      >
        <DoubleColumn
          title="Your own virtual gaming assistant"
          description="It’s an assistant for gamers and eSport players. It helps you perform tasks - without pausing an intense gaming session - like content creation or posting to social media."
          image={ColumnImage}
          top={20}
          flip
          small
          border={200}
          css={css`
            margin-bottom: 100px;
            padding-top: 50px;

            @media (max-width: 650px) {
              margin-bottom: 0;
            }
          `}
          imageOverride={css`
            @media (min-width: 651px) and (max-width: 1000px) {
              padding-left: 100px;
            }

            @media (min-width: 1920px) {
              height: 300px;
            }
          `}
        />
        <DoubleColumn
          title=""
          description="It’s an AI powered virtual assistant living in your PC and smartphone ready to help you with your gaming life. Fridai is the future of truly personalized gaming and provides frictionless, speedy access to everything you need."
          image={ColumnImage2}
          border={200}
          top={0}
          small
          css={css`
            margin-bottom: 50px;
          `}
          imageOverride={css`
            @media (min-width: 651px) and (max-width: 1000px) {
              padding-right: 100px;
            }

            @media (min-width: 1920px) {
              height: 300px;
            }
          `}
        />
      </div>
      <BannerDoubleColumn
        title=""
        description="The technology behind it is capable of understanding the context of speech, learns from the users and most importantly there is no new interface you need to get used to, you can use it in the most natural way."
        image={InstallImage}
        top={0}
        border={50}
        flip
        color="#131435"
        footer={Footer}
        column1={6}
        column2={4}
        css={css`
          padding-top: 100px;
          background-size: 100%;
          margin-bottom: 100px;

          @media (max-width: 650px) {
            padding-top: 50px;
          }
        `}
        imageOverride={css`
          @media (max-width: 650px) {
            height: 70vw;
          }

          @media (min-width: 1920px) {
            height: 500px;
            padding: 0;
          }
        `}
      />
      <Logo
        src={LogoImage}
        css={css`
          height: 140px;
          margin: 0 auto;
          margin-bottom: 150px;

          @media (max-width: 650px) {
            height: 25vw;
            margin-bottom: 0;
          }
        `}
      />
    </div>
  </Layout>
)

export default HallidaiProject
